import "./index.scss";

const Container = ({ noPadding, children }) => {
	return (
		<div className={`container${noPadding ? ' container_no-padding' : ''}`}>
			{children}
		</div>
	);
};

export default Container;
