import { useState, useEffect } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useGet, usePost } from '../../api/request';
import { setStages, setStagesById } from '../../redux/stage';

const dependencyIdName = {
	1: "applicants",
	2: "legends",
	3: "play_off"
};

export const useStagesPackaging = (stages) => {
	const user = useSelector(state => state.auth.user);
	const [closeStages, setCloseStages] = useState([]);
	const [closeTableStages, setCloseTableStages] = useState([]);
	const [closeGridStages, setCloseGridStages] = useState([]);
	const [openStages, setOpenStages] = useState([]);
	const [openTableStages, setOpenTableStages] = useState([]);
	const [openGridStages, setOpenGridStages] = useState([]);
	const [lockStages, setLockStages] = useState([]);
	const [isEndStages, setIsEndStages] = useState(false);
	const currentDate = moment().format();

	useEffect(() => {
		const localLockStages = stages.filter((stage) => moment(stage.date_start_betting).format() >= currentDate && !user?.bets[dependencyIdName[stage.id]] && !stage.event_over);
		const localOpenStages = stages.filter((stage) => moment(stage.date_start_betting).format() < currentDate && !stage.event_over);
		const localCloseStages = stages.filter((stage) => !!stage.event_over)
		if (localOpenStages.length === 0 && localLockStages.length !== 0 && localCloseStages.length > 0) {
			if(!!user?.bets[dependencyIdName[localCloseStages[localCloseStages.length-1]?.id]]) {
				localOpenStages.push(localCloseStages.pop());
			}
		}
		setLockStages(localLockStages);
		setOpenStages(localOpenStages);
		setCloseStages(localCloseStages);
		if (stages.length > 0) {
			setIsEndStages(stages.filter((stage) => !!stage.event_over).length === stages.length);
		}
	}, [stages, currentDate, user]);

	useEffect(() => {
		setCloseTableStages(closeStages.filter((stage) => stage.id !== 3));
		setCloseGridStages(closeStages.filter((stage) => stage.id === 3));
	}, [closeStages]);

	useEffect(() => {
		setOpenTableStages(openStages.filter((stage) => stage.id !== 3));
		setOpenGridStages(openStages.filter((stage) => stage.id === 3));
	}, [openStages]);

	return {
		closeStages,
		closeTableStages,
		closeGridStages,
		openStages,
		openTableStages,
		openGridStages,
		lockStages,
		isEndStages
	};
};

export const calcPoints = (teams = []) => {
	if (!teams?.length) {
		return 0;
	}
	return teams.reduce((value, team) => {
		return value + (team?.bet?.point || 0)
	}, 0);
}

export const calcGridPoints = (stageGrid) => {
	if (!stageGrid) {
		return 0;
	}
	let one = stageGrid["1/1"].reduce((value, match) => {
		return value + (match?.bet?.point || 0)
	}, 0);
	let two = stageGrid["1/2"].reduce((value, match) => {
		return value + (match?.bet?.point || 0)
	}, 0);
	let four = stageGrid["1/4"].reduce((value, match) => {
		return value + (match?.bet?.point || 0)
	}, 0);

	return one + two + four;
}

export const useExistResponseStage = () => {
	const user = useSelector((state) => state.auth.user);
	return (id) => {
		let stageTheams = user?.bets[dependencyIdName[id]];
		return stageTheams;
	};
};

export const usePlaceBetStageTable = () => {
	const post = usePost();
	return (id, { passedTeam = [], teamWin, teamLose }) => {
		const requestData = {
			passed_team: passedTeam,
			team_win: teamWin,
			team_lose: teamLose
		}
		return post(`stage/${id}`, requestData);
	};
};

export const usePlaceBetStageGrid = () => {
	const post = usePost();
	return ({ one, two, four }) => {
		const requestData = {
			"campaign": {
				"1/4": four.map((item) => ({
					"id": item.id,
					"team_id_passed": item.teamId
				})),
				"1/2": two.map((item) => ({
					"id": item.id,
					"team_id_passed": item.teamId
				})),
				"1/1": one.map((item) => ({
					"id": item.id,
					"team_id_passed": item.teamId
				}))
			}
		};
		return post('champions', requestData);
	};
};



export const useGetTeamsById = () => {
	const stagesById = useSelector((state) => state.stage.stagesById);
	return (id) => {
		const stage = stagesById[id];
		if (!stage) {
			return [];
		}
		return stage.teams || [];
	};
};

export const useGetStageById = () => {
	const stagesById = useSelector((state) => state.stage.stagesById);
	return (id) => {
		const stage = stagesById[id];
		if (!stage) {
			return [];
		}
		return stage || {};
	};
};

export const useFetchStagesTableById = () => {
	const dispatch = useDispatch();
	const get = useGet();
	return (id) => get(`stage/${id}`)
	.then((response) => {
		dispatch(setStagesById({
			id,
			stage: response.data
		}));
	})
	.catch(console.log);
};

export const useFetchStagesGridById = () => {
	const dispatch = useDispatch();
	const get = useGet();
	return (id) => get('champions')
	.then((response) => {
		dispatch(setStagesById({
			id,
			stage: response.data.grid
		}));
	})
	.catch(console.log);
};

export const useFetchStages = () => {
	const fetchStagesTableById = useFetchStagesTableById();
	const fetchStagesGridById = useFetchStagesGridById();
	const dispatch = useDispatch();
	const get = useGet();
	return () => get('stage')
	.then((response) => {
		dispatch(setStages(response.data?.stages))
		response.data.stages.map((stage) => {
			if (stage.id !== 3) {
				fetchStagesTableById(stage.id);
			} else {
				fetchStagesGridById(stage.id);
			}
		})
	})
	.catch(console.log);
};

const StagesController = ({ children }) => {
	const fetchStages = useFetchStages();

	useEffect(() => {
		fetchStages()
	}, [])

	return children;
}

export default StagesController;
