import { useTranslate } from "../../controllers/TranslateController";
import phrases from "../../phrases.json";
import "./index.scss";

const GiveawaySection = ({
	layout,
	rang,
	title,
	minPoints,
	points,
	imgDesktop,
	imgMob
}) => {
	const isParticipate = () => minPoints <= points;
	const translate = useTranslate();

	const renderParticipate = () => {
		if (isParticipate()) {
			return (
				<p className="giveaway-section__participate giveaway-section__participate_yes">
					{translate(phrases.giveawaySection1)}
					<svg viewBox="0 0 27 26">
						<use href="/img/done.svg#done"/>
					</svg>
				</p>
			);
		}
		return (
			<p className="giveaway-section__participate giveaway-section__participate_no">
				{translate(phrases.giveawaySection2)} {minPoints}
				<img src="/img/point.svg" alt="point"/>
				{translate(phrases.giveawaySection3).toLowerCase()} 
				<svg viewBox="0 0 50 50">
					<use href="/img/lock.svg#lock"/>
				</svg>
			</p>
		);
	};

	const renderTitle = () => {
		switch (rang) {
			case "silver": return (
				<p className="giveaway-section__title giveaway-section__title_silver">
					{title}
					<svg viewBox="0 0 42 38">
						<use href="/img/trophy.svg#trophy"/>
					</svg>
				</p>
			);
			break;
			case "gold": return (
				<p className="giveaway-section__title giveaway-section__title_gold">
					{title}
					<svg viewBox="0 0 42 38">
						<use href="/img/trophy.svg#trophy"/>
					</svg>
					<svg viewBox="0 0 42 38">
						<use href="/img/trophy.svg#trophy"/>
					</svg>
				</p>
			);
			break;
			case "diamond": return (
				<p className="giveaway-section__title giveaway-section__title_diamond">
					{title}
					<svg viewBox="0 0 38 38">
						<use href="/img/diamond.svg#diamond"/>
					</svg>
				</p>
			);
			break;
			default: return (
				<p className="giveaway-section__title">
					{title}
				</p>
			);
		}
	};

	return (
		<div className="giveaway-section">
			{layout(
				<div className={`giveaway-section__box giveaway-section__box_${rang}`}>
					<div className="giveaway-section__head">
						{renderTitle()}
						{renderParticipate()}
					</div>
					<div className="giveaway-section__body">
						<img className="giveaway-section__img-desktop" src={imgDesktop} alt="giveaway"/>
						<img className="giveaway-section__img-mob" src={imgMob} alt="giveaway"/>
					</div>
				</div>
			)}
		</div>
	);
};

export default GiveawaySection;
