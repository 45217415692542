// import { useRequestNotification } from "../../controllers/NotificationController";
import { useTranslate } from "../../controllers/TranslateController";
import phrases from "../../phrases.json";
import "./index.scss";

const NotificationsSection = ({
	layout,
	Button
}) => {
	// const requestNotification = useRequestNotification();
	const translate = useTranslate();


	// const handleSubscribe = () => {
		// requestNotification((e) => setError(e));
	// };

	return (
		<>
			<div className="notifications-section">
				{layout(
					<div className="notifications-section__box">
						<p className="notifications-section__text">
							{translate(phrases.notificationsSection1)}
						</p>
						<div className="notifications-section__btn">
							<Button className="sp_notify_prompt">
								<p>{translate(phrases.notificationsSection2).toUpperCase()}</p>
							</Button>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default NotificationsSection;
