import React, { useRef, useContext, useState } from "react";
import { useTranslate } from "../../controllers/TranslateController";
import phrases from "../../phrases.json";
import getEnvVars from "../../api/environment";
import "./index.scss";

const { uploadsUrl } = getEnvVars();

const Context = React.createContext();

export const useSelectTeam = () => {
	const { handleOpen } = useContext(Context);

	return {
		openSelectTeam: handleOpen
	};
};

const SelectTeam = ({ children }) => {
	const [onSelect, setOnSelect] = useState(() => () => {});
	const [items, setItems] = useState([]);
	const dialogInnerRef = useRef();
	const translate = useTranslate();

	const bodyScroll = (status) => {
		document.querySelector('body').style.overflow = status ? 'auto' : 'hidden';
	}

	const handleOpen = (items, initOnSelect) => {
		setOnSelect(() => initOnSelect)
		setItems(items);
		dialogInnerRef.current.showModal();
		bodyScroll(false);
	};

	const handleSelect = (item) => {
		onSelect(item.data);
		dialogInnerRef.current.close();
		setOnSelect(() => () => {})
		bodyScroll(true);
	};

	const handleClose = () => {
		dialogInnerRef.current.close();
		setOnSelect(() => () => {});
		bodyScroll(true);
	};

	return (
		<Context.Provider value={{
			handleOpen
		}}>
			{children}
			<dialog ref={dialogInnerRef} className="select-team">
				<div className="select-team__head">
					<button onClick={handleClose} className="select-team__close">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none">
							<path d="M29.855 8.38222L20 18.2372L10.145 8.38222C9.908 8.16532 9.59642 8.04823 9.27521 8.05535C8.954 8.06247 8.64792 8.19324 8.42073 8.42043C8.19354 8.64762 8.06277 8.9537 8.05565 9.27491C8.04854 9.59612 8.16562 9.9077 8.38253 10.1447L18.2325 19.9997L8.38003 29.8522C8.25966 29.9669 8.16343 30.1046 8.09701 30.257C8.0306 30.4094 7.99532 30.5736 7.99326 30.7399C7.9912 30.9061 8.0224 31.0711 8.08502 31.2251C8.14765 31.3792 8.24043 31.5191 8.35792 31.6368C8.47541 31.7545 8.61524 31.8474 8.76918 31.9103C8.92313 31.9731 9.08808 32.0046 9.25434 32.0027C9.42061 32.0009 9.58483 31.9659 9.73736 31.8997C9.88989 31.8335 10.0276 31.7374 10.1425 31.6172L20 21.7647L29.855 31.6197C30.0921 31.8366 30.4036 31.9537 30.7248 31.9466C31.0461 31.9395 31.3521 31.8087 31.5793 31.5815C31.8065 31.3543 31.9373 31.0482 31.9444 30.727C31.9515 30.4058 31.8344 30.0942 31.6175 29.8572L21.7625 20.0022L31.6175 10.1447C31.7379 10.03 31.8341 9.89239 31.9005 9.73996C31.967 9.58753 32.0022 9.42335 32.0043 9.25709C32.0064 9.09083 31.9752 8.92583 31.9125 8.7718C31.8499 8.61777 31.7571 8.47781 31.6396 8.36015C31.5221 8.24249 31.3823 8.14951 31.2284 8.08667C31.0744 8.02383 30.9095 7.99239 30.7432 7.99422C30.5769 7.99604 30.4127 8.03108 30.2602 8.09729C30.1077 8.16349 29.9699 8.25952 29.855 8.37972V8.38222Z" fill="white"/>
						</svg>
					</button>
					<p>{translate(phrases.selectTeam1)}</p>
				</div>
				<div className="select-team__wrap">
					{items.map((item) => (
						<div key={item.id} onClick={() => handleSelect(item)} className="select-team__item">
							<img src={uploadsUrl + item.img} alt="team"/>
						</div>
					))}
				</div>
			</dialog>
		</Context.Provider>
	);
};

export default SelectTeam;