const defaultENV = process.env.REACT_APP_STATUS;

const getEnvVars = () => {
	return {
		apiUrl: process.env.REACT_APP_API_URL,
		uploadsUrl: process.env.REACT_APP_UPLOADS_URL,
		pushScript: process.env.REACT_APP_PUSH_SCRIPT
	};
};

export default getEnvVars;
