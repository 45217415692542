import React, { useState, useEffect, useContext } from "react";
import { v4 as uuid } from "uuid";
import "./index.scss";

const Context = React.createContext();

const useAlert = () => {
	const {
		setList,
		onDeletListItem
	} = useContext(Context);

	return ({ title = '', text = '' }) => {
		const id = uuid();
		setList((prev) => [
			...prev,
			{
				id,
				title,
				text
			}
		]);
	};
}

const AlertItem = ({ item, onDeletListItem }) => {
	useEffect(() => {
		const timeoutID = setTimeout(() => {
			onDeletListItem(item.id);
		}, 3000);
		return () => {
			clearTimeout(timeoutID);
		};
	}, []);
	return (
		<div key={item.id} className="alert">
			<button className="alert__close" onClick={() => onDeletListItem(item.id)}>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 11" fill="none">
					<path d="M11 0.726403L10.2735 0L5.5 4.77353L0.726467 0L0 0.726403L4.77357 5.49997L0 10.2735L0.726467 10.9999L5.5 6.2264L10.2735 10.9999L11 10.2735L6.22644 5.49997L11 0.726403Z" fill="#CCCCCC"/>
				</svg>
			</button>
			<div className="alert__icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
					<path d="M20 10C20 15.5229 15.5229 20 10 20C4.47714 20 0 15.5229 0 10C0 4.47714 4.47714 0 10 0C15.5229 0 20 4.47714 20 10ZM8.84331 15.2949L16.2627 7.87556C16.5146 7.62363 16.5146 7.21512 16.2627 6.96319L15.3503 6.05081C15.0983 5.79883 14.6898 5.79883 14.4379 6.05081L8.3871 12.1015L5.56214 9.27657C5.3102 9.02464 4.90169 9.02464 4.64972 9.27657L3.73734 10.189C3.4854 10.4409 3.4854 10.8494 3.73734 11.1013L7.93089 15.2949C8.18286 15.5469 8.59133 15.5469 8.84331 15.2949Z" fill="#CF29FF"/>
				</svg>
			</div>
			<p className="alert__title">
				{item.title}
			</p>
			<p className="alert__text">
				{item.text}
			</p>
		</div>
	);
}

const AlertProvider = ({ children }) => {
	const [list, setList] = useState([]);

	const onDeletListItem = (id) => {
		setList((prev) => prev.filter((item) => item.id !== id));
	}

	return (
		<Context.Provider value={{
				setList,
				onDeletListItem
			}}
			>
			<div className="alert-box">
				{list.map((item) => (
					<AlertItem key={item.id} item={item} onDeletListItem={onDeletListItem} />
				))}
			</div>
			{children}
		</Context.Provider>
		);
}

export { AlertProvider, useAlert };

