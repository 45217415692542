import "./index.scss";

const Logo = () => {
	return (
		<>
			<img src="/img/logo.png" alt="logo" />
		</>
	);
};

export default Logo;
