import { useId } from "react";
import ReactTooltip from "react-tooltip";
import getEnvVars from "../../api/environment";
import "./index.scss";

const { uploadsUrl } = getEnvVars();

const DragTeams = ({
	type,
	items,
	Drag
}) => {
	return (
		<div className="drag-teams">
			{items.map((item) => (
				<Drag type={type} key={useId()} data={item}>
					{({ ref, className }) => 
						<>
							<div ref={ref} type={type} className={`drag-teams__item ${className ? className : ''}`} {...{"data-tip": true, "data-for": type+item.team_id}}>
								<img src={uploadsUrl + item.team_logo} alt="team"/>
							</div>
							<ReactTooltip id={type+item.team_id} place="top" effect="solid">
								{item.team_name}
							</ReactTooltip>
						</>
					}
				</Drag>
			))}
		</div>
	);
};

export default DragTeams;
