// import { Link } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import { useTranslate } from "../../controllers/TranslateController";
import phrases from "../../phrases.json";
import "./index.scss";

const Navbar = ({ isMob }) => {
	const translate = useTranslate();
	return (
		<ul className={`navbar ${isMob ? 'navbar_mob' : ''}`}>
			<li className="navbar__item">
				<Link className="navbar__link" to="#about">
					{translate(phrases.navbar1)}
				</Link>
			</li>
			<li className="navbar__item">
				<Link className="navbar__link" to="#pickem">
					{translate(phrases.navbar2)}
				</Link>
			</li>
			<li className="navbar__item">
				<Link className="navbar__link" to="#prizes">
					{translate(phrases.navbar3)}
				</Link>
			</li>
			<li className="navbar__item">
				<Link className="navbar__link" to="#contacts">
					{translate(phrases.navbar4)}
				</Link>
			</li>
		</ul>
	);
};

export default Navbar;
