import { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { setLang } from '../../redux/translate';
import getEnvVars from "../../api/environment";
import "./index.scss";

const { uploadsUrl } = getEnvVars();

const LangSelect = () => {
	const lang = useSelector(state => state.translate.lang);
	const langList = useSelector(state => state.translate.langList);
	const [isOpen, setIsOpen] = useState(false);
	const dispatch = useDispatch();

	const handleClick = () => {
		setIsOpen((prev) => !prev);
	}

	const handleSelect = (lng) => {
		dispatch(setLang(lng));
		setIsOpen(false);
	}

	return (
		<div className="lang-select">
			<button className="lang-select__wrap" onClick={handleClick}>
				<div className="lang-select__flag">
					<img src={lang.image ? uploadsUrl + lang.image : `/img/flags/${lang.tag}.png`}/>
				</div>
				<p className="lang-select__text">
					{String(lang.tag).toUpperCase()}
				</p>
				<div className="lang-select__arrow">
					{isOpen ?
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21" fill="none">
							<path d="M4.94738 13.0536C5.03576 13.1422 5.14073 13.2125 5.2563 13.2604C5.37187 13.3083 5.49575 13.333 5.62087 13.333C5.74598 13.333 5.86986 13.3083 5.98543 13.2604C6.101 13.2125 6.20597 13.1422 6.29435 13.0536L10.0009 9.34707L13.7075 13.0536C13.8861 13.2323 14.1284 13.3326 14.381 13.3326C14.6336 13.3326 14.8758 13.2323 15.0545 13.0536C15.2331 12.875 15.3334 12.6328 15.3334 12.3801C15.3334 12.1275 15.2331 11.8853 15.0545 11.7067L10.6696 7.32184C10.5812 7.23328 10.4763 7.16302 10.3607 7.11508C10.2451 7.06714 10.1213 7.04247 9.99614 7.04247C9.87102 7.04247 9.74714 7.06714 9.63157 7.11508C9.51601 7.16302 9.41103 7.23328 9.32265 7.32184L4.93783 11.7067C4.57481 12.0697 4.57481 12.6811 4.94738 13.0536Z" fill="currentColor"/>
						</svg>
						:
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21" fill="none">
							<path d="M15.0549 6.94832C14.9665 6.85978 14.8615 6.78953 14.7459 6.74161C14.6303 6.69369 14.5064 6.66903 14.3813 6.66904C14.2562 6.66906 14.1323 6.69375 14.0168 6.74171C13.9012 6.78966 13.7962 6.85993 13.7079 6.94851L10.0018 10.6556L6.29476 6.9495C6.11611 6.77091 5.87384 6.67059 5.62124 6.67063C5.36863 6.67066 5.12638 6.77104 4.94778 6.94969C4.76919 7.12833 4.66888 7.3706 4.66891 7.62321C4.66894 7.87582 4.76932 8.11806 4.94797 8.29666L9.33338 12.6809C9.42177 12.7694 9.52676 12.8397 9.64233 12.8876C9.7579 12.9355 9.88179 12.9602 10.0069 12.9602C10.132 12.9602 10.2559 12.9355 10.3715 12.8875C10.487 12.8396 10.592 12.7693 10.6804 12.6807L15.0646 8.2953C15.4276 7.93223 15.4275 7.32084 15.0549 6.94832Z" fill="currentColor"/>
						</svg>
					}
				</div>
			</button>
			{isOpen && (
				<div className="lang-select__list">
					{langList.map((lng) => (
						<button key={lng.id} className="lang-select__list-item" onClick={() => handleSelect(lng)}>
							<div className="lang-select__flag">
								<img src={lng.image ? uploadsUrl + lng.image : `/img/flags/${lng.tag}.png`}/>
							</div>
							<p className="lang-select__text">
								{String(lng.tag).toUpperCase()}
							</p>
						</button>
					))}
				</div>
			)}
		</div>
	);
};

export default LangSelect;
