import { useDrag } from "react-dnd";
import './index.scss';

const Drag = ({ type, data, children }) => {
	const [{ isDragging }, drag] = useDrag(() => ({
		type: type,
		item: data
	}), [data])
	return children({ isDragging, ref: drag, className: 'drag'});
};

export default Drag;
