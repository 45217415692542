import { useState } from "react";
import { useTranslate } from "../../controllers/TranslateController";
import { useSelectTeam } from "../../components/SelectTeam";
import phrases from "../../phrases.json";
import getEnvVars from "../../api/environment";
import "./index.scss";

const { uploadsUrl } = getEnvVars();

const GridTeam = ({
	type,
	teams = [],
	items,
	offSelect,
	resultField,
	onChange,
	Drag,
	Drop
}) => {
	const {
		openSelectTeam
	} = useSelectTeam();
	const translate = useTranslate();

	const handleSelect = (index) => {
		if (offSelect) {
			return;
		}
		const arrTeams = [
			teams[0].team_id_one,
			teams[0].team_id_two,
			teams[1].team_id_one,
			teams[1].team_id_two,
			teams[2].team_id_one,
			teams[2].team_id_two,
			teams[3].team_id_one,
			teams[3].team_id_two
		];
		openSelectTeam(arrTeams.map((team) => {
			return {
				img: team.logo,
				id: team.id,
				data: team
			};
		}), (data) => {
			onChange(index, data)
		});
	};

	const renderResult = (data) => {
		if (!data) {
			return null;
		}
		if (resultField === undefined) {
			return null;
		}
		if (data[resultField] === true) {
			return <img className="grid-team__success" src="/img/result-team-success.svg" alt="success"/>;
		}
		if (data[resultField] === false) {
			return <img className="grid-team__error" src="/img/result-team-error.svg" alt="error"/>;
		}
		return null;
	}

	const renderTeams = (index, locTypeDrag) => (
		<div className={`grid-team__wrap grid-team__wrap_team-${index}`}>
			<div className="grid-team__wrap-cell">
				<Drag type={`${type} ${locTypeDrag}`} data={{ data: JSON.stringify(teams[index]?.team_id_one) }}>
					{({ ref, className }) => 
						<div ref={ref} className={`grid-team__cell ${className}`}>
							<img src={uploadsUrl + teams[index]?.team_id_one?.logo}/>
							<p>
								{teams[index]?.team_id_one?.name}
							</p>
						</div>
					}
				</Drag>
			</div>
			<div className="grid-team__wrap-cell">
				<Drag type={`${type} ${locTypeDrag}`} data={{ data: JSON.stringify(teams[index]?.team_id_two) }}>
					{({ ref, className }) => 
						<div ref={ref} className={`grid-team__cell ${className}`}>
							<img src={uploadsUrl + teams[index]?.team_id_two?.logo}/>
							<p>
								{teams[index]?.team_id_two?.name}
							</p>
						</div>
					}
				</Drag>
			</div>
		</div>
	);

	const renderTeamsMob = (index) => (
		<>
			{!!teams[index]?.team_id_one && (
				<div className="grid-team__team">
					<img src={uploadsUrl + teams[index].team_id_one.logo}/>
				</div>
			)}
			{!!teams[index]?.team_id_two && (
				<div className="grid-team__team">
					<img src={uploadsUrl + teams[index].team_id_two.logo}/>
				</div>
			)}
		</>
	);

	const renderCellMob = (index) => (
		<div onClick={() => handleSelect(index)} className="grid-team__mob-cell">
			{renderResult(items[index])}
			{items[index] ?
				<img src={uploadsUrl + items[index].logo}/>
				:
				<p>
					<span>?</span>
					{translate(phrases.gridTeam1)}
				</p>
			}
		</div>
	);

	const renderCell = (index, locTypeDrop, locTypeDrag) => {
		return (
			<div className={`grid-team__wrap grid-team__wrap_${index}`}>
				<div className="grid-team__wrap-cell">
					<Drop
						type={`${type} ${locTypeDrop}`}
						onDrop={(data) => {
							let result = JSON.parse(data.data)
							if (result.id) {
								onChange(index, result)
							}
						}}>
						{({ isOver, ref }) => 
							<div ref={ref}>
								<Drag type={`${type} ${locTypeDrag}`} data={{ data: JSON.stringify(items[index]) }}>
									{({ ref, className }) => 
										<div ref={ref} className={`grid-team__cell ${isOver ? 'grid-team__cell_over' : ''} ${className}`}>
											{renderResult(items[index])}
											{items[index] &&
												<>
													<img src={uploadsUrl + items[index].logo}/>
													<p>
														{items[index].name}
													</p>
												</>
											}
										</div>
									}
								</Drag>
							</div>
						}
					</Drop>
				</div>
			</div>
		);
	};

	const renderTextPick1 = () => {
		let arr = translate(phrases.gridTeam2).match(/(^\S+\s)(.+)(\s+\S+\s+\S+\s+\S+\s+\S+\s+\S+)$/);
		if (!arr) {
			return translate(phrases.gridTeam2);
		}
		return <>
			<span>{arr[1]}</span>{arr[2]}<span>{arr[3]}</span>
		</>
	}

	const renderTextPick2 = () => {
		let arr = translate(phrases.gridTeam3).match(/(^\S+\s)(.+)(\s+\S+\s+\S+\s+\S+\s+\S+\s+\S+)$/);
		if (!arr) {
			return translate(phrases.gridTeam3);
		}
		return <>
			<span>{arr[1]}</span>{arr[2]}<span>{arr[3]}</span>
		</>
	}

	const renderTextPick3 = () => {
		let arr = translate(phrases.gridTeam4).match(/(^\S+\s)(.+)(\s+\S+\s+\S+)$/);
		if (!arr) {
			return translate(phrases.gridTeam4);
		}
		return <>
			<span>{arr[1]}</span>{arr[2]}<span>{arr[3]}</span>
		</>
	}

	return (
		<div className="grid-team">
			<div className="grid-team__mob">
				<div className="grid-team__wrap-team">
					{renderTeamsMob(0)}
					{renderTeamsMob(1)}
					{renderTeamsMob(2)}
					{renderTeamsMob(3)}
				</div>
				<div className="grid-team__mob-wrap">
					<p className="grid-team__mob-pick">
						{renderTextPick1()}
					</p>
					{renderCellMob(0)}
					{renderCellMob(1)}
					{renderCellMob(2)}
					{renderCellMob(3)}
				</div>
				<div className="grid-team__mob-wrap">
					<p className="grid-team__mob-pick">
						{renderTextPick2()}
					</p>
					{renderCellMob(4)}
					{renderCellMob(5)}
				</div>
				<div className="grid-team__mob-wrap">
					<p className="grid-team__mob-pick">
						{renderTextPick3()}
					</p>
					{renderCellMob(6)}
				</div>
			</div>
			<div className="grid-team__desktop">
				{renderTeams(0, '1/4')}
				{renderTeams(1, '2/4')}
				{renderTeams(2, '3/4')}
				{renderTeams(3, '4/4')}
				{renderCell(0, '1/4', '1/2')}
				{renderCell(1, '2/4', '1/2')}
				{renderCell(2, '3/4', '2/2')}
				{renderCell(3, '4/4', '2/2')}
				{renderCell(4, '1/2', '1/1')}
				{renderCell(5, '2/2', '1/1')}
				{renderCell(6, '1/1')}
			</div>
		</div>
	);
};

export default GridTeam;
