import "./index.scss";

const LogoMob = () => {
	return (
		<>
			<img src="/img/logo-mob.png" alt="logo-mob" />
		</>
	);
};

export default LogoMob;
