import "./index.scss";

const ButtonAuthMob = ({ onClick, isLogin, children }) => {
	return (
		<button className="button-auth-mob" onClick={onClick}>
			{isLogin ?
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" fill="none">
					<path fillRule="evenodd" clipRule="evenodd" d="M13.4617 0C16.2231 0 18.4617 2.23858 18.4617 5V9.92675H11.1926L11.988 9.073L12.1478 8.87822C12.4471 8.46172 12.5878 7.93826 12.5414 7.41415C12.495 6.89005 12.265 6.40459 11.898 6.05639C11.531 5.7082 11.0546 5.52336 10.5655 5.53943C10.0765 5.5555 9.61144 5.77127 9.26488 6.14291L9.26295 6.14084L7.22159 8.33737L5.18024 10.5339L5.0204 10.7287C4.73272 11.1271 4.59004 11.6249 4.61911 12.1284C4.64818 12.632 4.84702 13.1069 5.17831 13.464L9.26102 17.8633L9.44397 18.0353C9.83113 18.3539 10.3162 18.5027 10.8013 18.4517C11.2864 18.4007 11.7355 18.1536 12.058 17.7603C12.3805 17.3671 12.5524 16.8568 12.539 16.3326C12.5257 15.8083 12.3281 15.3089 11.986 14.9353L11.1849 14.0712H18.4617V19C18.4617 21.7614 16.2231 24 13.4617 24H5.00013C2.23871 24 0.000131607 21.7614 0.000131607 19V5C0.000131607 2.23858 2.23871 0 5.00013 0H13.4617Z" fill="#D9D9D9"/>
					<path opacity="0.8" fillRule="evenodd" clipRule="evenodd" d="M18.4614 9.92681H22.956C23.4568 9.92318 23.9398 10.1237 24.3053 10.4869C24.6707 10.8501 24.8907 11.3483 24.9198 11.8786C24.9489 12.4089 24.7849 12.9308 24.4616 13.3365C24.1383 13.7422 23.6805 14.0008 23.1827 14.0588L22.956 14.0712H18.4614V9.92681Z" fill="#FFFEFE"/>
				</svg>
				:
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" fill="none">
					<path fillRule="evenodd" clipRule="evenodd" d="M11.4615 0C8.70012 0 6.46155 2.23858 6.46155 5V9.92675H13.7306L12.9353 9.073L12.7754 8.87822C12.4761 8.46172 12.3354 7.93826 12.3818 7.41415C12.4282 6.89005 12.6582 6.40459 13.0252 6.05639C13.3922 5.7082 13.8686 5.52336 14.3577 5.53943C14.8467 5.5555 15.3118 5.77127 15.6583 6.14291L15.6603 6.14084L17.7016 8.33737L19.743 10.5339L19.9028 10.7287C20.1905 11.1271 20.3332 11.6249 20.3041 12.1284C20.275 12.632 20.0762 13.1069 19.7449 13.464L15.6622 17.8633L15.4792 18.0353C15.0921 18.3539 14.607 18.5027 14.1219 18.4517C13.6368 18.4007 13.1877 18.1536 12.8652 17.7603C12.5427 17.3671 12.3708 16.8568 12.3842 16.3326C12.3975 15.8083 12.5952 15.3089 12.9372 14.9353L13.7383 14.0712H6.46155V19C6.46155 21.7614 8.70012 24 11.4615 24H19.9231C22.6845 24 24.9231 21.7614 24.9231 19V5C24.9231 2.23858 22.6845 0 19.9231 0H11.4615Z" fill="#D9D9D9"/>
					<path opacity="0.8" fillRule="evenodd" clipRule="evenodd" d="M6.46173 9.92681H1.96706C1.46625 9.92318 0.983248 10.1237 0.617804 10.4869C0.252362 10.8501 0.0323856 11.3483 0.00330044 11.8786C-0.0257866 12.4089 0.138242 12.9308 0.461509 13.3365C0.784777 13.7422 1.2426 14.0008 1.74043 14.0588L1.96706 14.0712H6.46173V9.92681Z" fill="#FFFEFE"/>
				</svg>
			}
		</button>
	);
};

export default ButtonAuthMob;
