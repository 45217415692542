import { useState, useEffect } from "react";
import ForecastSection from "../../sections/ForecastSection";
import DragTeams from "../../components/DragTeams";
import TableTeams from "../../components/TableTeams";
import { usePlaceBetStageTable } from "../../controllers/StagesController";
import EmptyWithReturn from "../../layouts/EmptyWithReturn";

export const turnIntoTableItems = (initItems) => {
	if (!initItems) {
		return {};
	}
	let teamWin = initItems.find((team) => team.bet_type_id === 2);
	let teamLose = initItems.find((team) => team.bet_type_id === 3);
	let passedTeam = initItems.filter((team) => team.bet_type_id === 1);
	const createItem = (team) => ({
		team_logo: team?.team_id?.logo,
		team_id: team?.team_id?.id,
		win: team?.bet?.right
	});
	return ({
		0: createItem(teamWin),
		1: createItem(teamLose),
		2: createItem(passedTeam[0]),
		3: createItem(passedTeam[1]),
		4: createItem(passedTeam[2]),
		5: createItem(passedTeam[3]),
		6: createItem(passedTeam[4]),
		7: createItem(passedTeam[5]),
		8: createItem(passedTeam[6])
	});
}

const turnIntoPlaceBetStage = (items) => {
	return {
		passedTeam: [
			items['2'].team_id,
			items['3'].team_id,
			items['4'].team_id,
			items['5'].team_id,
			items['6'].team_id,
			items['7'].team_id,
			items['8'].team_id
		],
		teamWin: items['0'].team_id,
		teamLose: items['1'].team_id
	};
}

const ForecastTableBlock = ({
	id,
	stageName,
	type,
	Drag,
	Drop,
	resultField,
	teams,
	initItems,
	isWaitResult,
	reload = () => {},
	...props
}) => {
	const [isTimerEnd, setIsTimerEnd] = useState(false);
	const [items, setItems] = useState({});
	const [isReady, setIsReady] = useState(false);
	const [isWaitResultLocal, setIsWaitResultLocal] = useState(isWaitResult);
	const placeBetStageTable = usePlaceBetStageTable();

	useEffect(() => {
		setItems(turnIntoTableItems(initItems));
	}, [initItems]);

	useEffect(() => {
		if (Object.keys(items).length === 9) {
			setIsReady(true);
		} else {
			setIsReady(false);
		}
	}, [items]);

	const handleChange = (index, data) => {
		setItems((prev) => {
			let newPrev = {};
			for (const [key, value] of Object.entries(prev)) {
				if (value.id !== data.id) {
					newPrev[key] = value;
				}
			}
			return ({
				...newPrev,
				[index]: data
			})
		});
	}

	const handleSubmit = () => {
		placeBetStageTable(id, turnIntoPlaceBetStage(items))
		.then((response) => {
			setIsWaitResultLocal(true);
			reload();
		})
		.catch(console.log);
	}

	return (
		<ForecastSection
			{...props}
			stageName={stageName}
			isReady={isTimerEnd ? false : isReady}
			isWaitResult={isWaitResultLocal}
			onExpire={() => {
				setIsTimerEnd(true);
				reload()
			}}
			onSubmit={handleSubmit}>
			<DragTeams
				type={type}
				Drag={isTimerEnd ? EmptyWithReturn : Drag}
				items={teams}/>
			<TableTeams
				type={type}
				Drop={isTimerEnd ? EmptyWithReturn : Drop}
				offSelect={isTimerEnd}
				resultField={resultField}
				teams={teams}
				items={items}
				onChange={handleChange}/>
		</ForecastSection>
	);
}

export default ForecastTableBlock;