import { useEffect } from "react";
import { useDispatch } from 'react-redux';
import { get } from '../../api/request';
import { setConfig } from '../../redux/config';

export const useFetchConfig = () => {
	const dispatch = useDispatch();
	return (callback = () => {}) => get('general/settings')
	.then((response) => {
		const settings = response.data.settings.reduce((obj, setting) => {
			obj[setting.name_setting] = setting.value;
			return obj;
		}, {})
		const config = {
			discord: settings["Discord"],
			facebook: settings["Facebook"],
			privacyPolicy: settings["Privacy Policy"],
			termsOfService: settings["Terms of Service"],
			twitter: settings["Twitter"],
			youtube: settings["YouTube"],
			promoCode: settings["Промокод"],
			isOpenResulWindow: settings["OpenResultWindow"]
		}
		dispatch(setConfig(config));
	})
	.catch((e) => {
		console.log(e);
	})
}

const ConfigController = ({ children }) => {
	const fetchConfig = useFetchConfig();

	useEffect(() => {
		fetchConfig();
	}, []);

	return children;
}

export default ConfigController;
