import { useTranslate } from "../../controllers/TranslateController";
import phrases from "../../phrases.json";
import "./index.scss";

const FooterSection = ({
	layout,
	navbar,
	socialbar,
	policybar
}) => {
	const translate = useTranslate();

	return (
		<div className="footer-section">
			{layout(
				<div className="footer-section__box">
					<div className="footer-section__top">
						<div className="footer-section__navbar">
							{navbar}
						</div>
						<div className="footer-section__socialbar">
							{socialbar}
						</div>
					</div>
					<div className="footer-section__line"/>
					<div className="footer-section__bottom">
						<p className="footer-section__text">
							{translate(phrases.footerSection1)}
						</p>
						<div className="footer-section__policybar">
							{policybar}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default FooterSection;
