import { Navigate } from "react-router-dom";
import Main from "../pages/Main";

const navigation = [
	{
		path: '/',
		children: [
			{ path: '/', element: <Main /> },
			{ path: '*', element: <Navigate to="/" /> }
		]
	}
];

export default navigation;

