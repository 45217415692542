import { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { get, post, usePut } from '../../api/request';
import { setLangList, setTranslate } from '../../redux/translate';
import phrases from '../../phrases.json';

export const useTranslate = () => {
	const translate = useSelector(state => state.translate.translate);
	return (text) => translate[text] || text
};

export const useFetchTranslateById = () => {
	const dispatch = useDispatch();
	return (id) => {
		const values = new Set();
		for (let value of Object.values(phrases)) {
			if (value) {
				values.add(value)
			}
		}
		const requestData = {
			values: Array.from(values)
		}
		post(`translation/${id}`, requestData)
		.then((response) => {
			dispatch(setTranslate(response.data.phraseArray.reduce((obj, item) => {
				obj[item.phrase] = item.translation;
				return obj;
			}, {})));
		})
		.catch((e) => {
			console.log(e);
		})
	};
}

export const useFetchSetLangUser = () => {
	const lang = useSelector(state => state.translate.lang);
	const put = usePut();
	const dispatch = useDispatch();
	return () => {
		const requestData = {
			language_id: lang.id
		};
		put('users/language', requestData)
		.catch((e) => {
			console.log(e);
		})
	};
}


export const useFetchLangList = () => {
	const dispatch = useDispatch();
	return () => get('translation')
	.then((response) => {
		dispatch(setLangList(response.data.languages));
	})
	.catch((e) => {
		console.log(e);
	})
}

const TranslateController = ({ children }) => {
	const accessToken = useSelector(state => state.auth.accessToken);
	const lang = useSelector(state => state.translate.lang);
	const dispatch = useDispatch();
	const fetchConfig = useFetchLangList();
	const fetchTranslateById = useFetchTranslateById();
	const fetchSetLangUser = useFetchSetLangUser();

	useEffect(() => {
		fetchConfig();
	}, []);

	useEffect(() => {
		if (!accessToken) {
			return;
		}
		fetchSetLangUser();
	}, [accessToken, lang])

	useEffect(() => {
		if (!lang.id) {
			dispatch(setTranslate());
			return;
		}
		fetchTranslateById(lang.id);
	}, [lang]);

	return children;
};

export default TranslateController;