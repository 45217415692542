import { createSlice } from '@reduxjs/toolkit';

const initialLang = {
	id: null,
	name: "English",
	tag: "eng",
	image: null
}

export const counterSlice = createSlice({
	name: 'translate',
	initialState: {
		lang: initialLang,
		langList: [ initialLang ],
		translate: []
	},
	reducers: {
		setLangList: (state, action) => {
			state.langList = [ initialLang, ...(action.payload || [])];
		},
		setLang: (state, action) => {
			state.lang = action.payload || initialLang;
		},
		setTranslate: (state, action) => {
			state.translate = action.payload || [];
		}
	}
});

export const { setLangList, setLang, setTranslate } = counterSlice.actions;

export default counterSlice.reducer;
