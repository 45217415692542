import { AnimationOnScroll } from "react-animation-on-scroll";
import { useTranslate } from "../../controllers/TranslateController";
import phrases from "../../phrases.json";
import "./index.scss";

const TotalScoreSection = ({
	layout,
	score,
}) => {
	const translate = useTranslate();
	return (
		<div className="total-score-section">
			{layout(
				<AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce={true}>
					<div className="total-score-section__box">
						<p className="total-score-section__title">
							{translate(phrases.totalScoreSection1)} {score}
							<img src="/img/point.svg" alt="point"/>
							{translate(phrases.totalScoreSection2).toLowerCase()}
						</p>
						<p className="total-score-section__mob-title">
							{translate(phrases.totalScoreSection1)}
							<br/>
							{score}
							<img src="/img/point.svg" alt="point"/>
							{translate(phrases.totalScoreSection2).toLowerCase()}
						</p>
						<p className="total-score-section__text">
							{translate(phrases.totalScoreSection3)}
						</p>
						<div className="total-score-section__arrow">
							<img src="/img/block-arrow-down.svg" alt="arrow down"/>
						</div>
						<div className="total-score-section__mob-arrow">
							<img src="/img/block-arrow-down-mob.svg" alt="arrow down"/>
						</div>
					</div>
				</AnimationOnScroll>
			)}
		</div>
	);
};

export default TotalScoreSection;
