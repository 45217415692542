import { useTranslate } from "../../controllers/TranslateController";
import phrases from "../../phrases.json";
import "./index.scss";

const PassedStageSection = ({
	layout,
	lock,
	title = '',
	lockTitle = '',
	resultPoints = '',
	children
}) => {
	const translate = useTranslate();
	return (
		<div className="passed-stage-section">
			{layout(
				<p className="passed-stage-section__lock-title">{lockTitle}</p>
			)}
			{layout(
				<div className="passed-stage-section__box">
					<p className="passed-stage-section__title">{title}</p>
					<div className="passed-stage-section__wrap">
						{children}
					</div>
					<div className="passed-stage-section__btn">
						<p>{translate(phrases.passedStageSection1)} {resultPoints}</p>
						<img src="/img/point.svg" alt="point"/>
					</div>
					{lock && (
						<div className="passed-stage-section__lock">
							<svg viewBox="0 0 50 50">
								<use href="/img/lock.svg#lock"/>
							</svg>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default PassedStageSection;
