import { useRef, useEffect, useState } from "react";
import "./index.scss";

const ModalConfirm = ({ dialogRef, onSubmit, title, text, btnTextYes, btnTextNo }) => {
	const dialogInnerRef = useRef();

	useEffect(() => {
		dialogRef.current = {
			open: () => dialogInnerRef.current.showModal(),
			close: () => dialogInnerRef.current.close()
		};
	},[])

	const handleYes = () => {
		dialogInnerRef.current.close();
		onSubmit();
	};

	const handleNo = () => {
		dialogInnerRef.current.close();
	};

	return (
		<dialog ref={dialogInnerRef} className="modal-confirm">
			<p className="modal-confirm__title">
				{title}
			</p>
			<p className="modal-confirm__text">
				{text}
			</p>
			<div className="modal-confirm__wrap">
				<button className="modal-confirm__btn modal-confirm__btn_no" onClick={handleNo}>
					{btnTextNo}
				</button>
				<button className="modal-confirm__btn" onClick={handleYes}>
					{btnTextYes}
				</button>
			</div>
		</dialog>
	);
};

export default ModalConfirm;
