import { useDrop } from "react-dnd";

const Drop = ({ type, onDrop, children }) => {
	const [{ isOver }, drop] = useDrop(() => ({
		accept: type,
		drop: onDrop,
		collect: (monitor) => ({
			isOver: monitor.isOver(),
		}),
	}))
	return children({ isOver, ref: drop });
};

export default Drop;
