import { useState } from "react";
import { useTranslate } from "../../controllers/TranslateController";
import phrases from "../../phrases.json";
import "./index.scss";

const ProfileBar = ({
	img,
	name,
	points,
	onExit
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const translate = useTranslate();

	const handleClick = () => {
		setIsOpen((prev) => !prev);
	}

	const handleExit = () => {
		setIsOpen(false);
		onExit();
	}

	return (
		<div className="profile-bar">
			<div className="profile-bar__wrap">
				<div className="profile-bar__avatar">
					<img src={img} alt="avatar"/>
				</div>
				<p className="profile-bar__text">
					{name}
				</p>
				<button className="profile-bar__arrow" onClick={handleClick}>
					{isOpen ?
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21" fill="none">
							<path d="M4.94738 13.0536C5.03576 13.1422 5.14073 13.2125 5.2563 13.2604C5.37187 13.3083 5.49575 13.333 5.62087 13.333C5.74598 13.333 5.86986 13.3083 5.98543 13.2604C6.101 13.2125 6.20597 13.1422 6.29435 13.0536L10.0009 9.34707L13.7075 13.0536C13.8861 13.2323 14.1284 13.3326 14.381 13.3326C14.6336 13.3326 14.8758 13.2323 15.0545 13.0536C15.2331 12.875 15.3334 12.6328 15.3334 12.3801C15.3334 12.1275 15.2331 11.8853 15.0545 11.7067L10.6696 7.32184C10.5812 7.23328 10.4763 7.16302 10.3607 7.11508C10.2451 7.06714 10.1213 7.04247 9.99614 7.04247C9.87102 7.04247 9.74714 7.06714 9.63157 7.11508C9.51601 7.16302 9.41103 7.23328 9.32265 7.32184L4.93783 11.7067C4.57481 12.0697 4.57481 12.6811 4.94738 13.0536Z" fill="currentColor"/>
						</svg>
						:
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21" fill="none">
							<path d="M15.0549 6.94832C14.9665 6.85978 14.8615 6.78953 14.7459 6.74161C14.6303 6.69369 14.5064 6.66903 14.3813 6.66904C14.2562 6.66906 14.1323 6.69375 14.0168 6.74171C13.9012 6.78966 13.7962 6.85993 13.7079 6.94851L10.0018 10.6556L6.29476 6.9495C6.11611 6.77091 5.87384 6.67059 5.62124 6.67063C5.36863 6.67066 5.12638 6.77104 4.94778 6.94969C4.76919 7.12833 4.66888 7.3706 4.66891 7.62321C4.66894 7.87582 4.76932 8.11806 4.94797 8.29666L9.33338 12.6809C9.42177 12.7694 9.52676 12.8397 9.64233 12.8876C9.7579 12.9355 9.88179 12.9602 10.0069 12.9602C10.132 12.9602 10.2559 12.9355 10.3715 12.8875C10.487 12.8396 10.592 12.7693 10.6804 12.6807L15.0646 8.2953C15.4276 7.93223 15.4275 7.32084 15.0549 6.94832Z" fill="currentColor"/>
						</svg>
					}
				</button>
			</div>
			{isOpen && (
				<button className="profile-bar__exit" onClick={handleExit}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 16" fill="none">
						<path d="M13.8182 13.4545V8.72727H7.22727C7.03439 8.72727 6.8494 8.65065 6.71301 8.51426C6.57662 8.37787 6.5 8.19289 6.5 8C6.5 7.80712 6.57662 7.62213 6.71301 7.48574C6.8494 7.34935 7.03439 7.27273 7.22727 7.27273H13.8182V2.54545C13.8175 1.87058 13.549 1.22355 13.0718 0.746344C12.5946 0.269135 11.9476 0.000721921 11.2727 0H2.54545C1.87058 0.000721921 1.22355 0.269135 0.746344 0.746344C0.269135 1.22355 0.000721921 1.87058 0 2.54545V13.4545C0.000721921 14.1294 0.269135 14.7764 0.746344 15.2537C1.22355 15.7309 1.87058 15.9993 2.54545 16H11.2727C11.9476 15.9993 12.5946 15.7309 13.0718 15.2537C13.549 14.7764 13.8175 14.1294 13.8182 13.4545ZM17.8805 8.72727L15.4859 11.1223C15.3553 11.2598 15.2835 11.4429 15.286 11.6325C15.2884 11.8222 15.3648 12.0033 15.4989 12.1375C15.633 12.2716 15.8142 12.348 16.0039 12.3504C16.1935 12.3528 16.3766 12.2811 16.5141 12.1505L20.1505 8.51409C20.2867 8.37772 20.3633 8.1928 20.3633 8C20.3633 7.8072 20.2867 7.62229 20.1505 7.48591L16.5141 3.84955C16.3766 3.71891 16.1935 3.64716 16.0039 3.64959C15.8142 3.65202 15.633 3.72843 15.4989 3.86254C15.3648 3.99665 15.2884 4.17785 15.286 4.36749C15.2835 4.55714 15.3553 4.74023 15.4859 4.87773L17.8805 7.27273H13.8182V8.72727H17.8805Z" fill="url(#paint0_radial_273_12163)"/>
						<defs>
							<radialGradient id="paint0_radial_273_12163" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(1.4177) rotate(48.7167) scale(21.292 92.1661)">
								<stop stopColor="#9B34E9"/>
								<stop offset="1" stopColor="#272FCC"/>
							</radialGradient>
						</defs>
					</svg>
					<p>
						{translate(phrases.profileBar1)}
					</p>
				</button>
			)}
			<p className="profile-bar__box">
				{translate(phrases.profileBar2)}: {points}
				<img src="/img/point.svg" alt="point"/>
				{translate(phrases.profileBar3).toLowerCase()}
			</p>
		</div>
	);
};

export default ProfileBar;
