import { useTranslate } from "../../controllers/TranslateController";
import phrases from "../../phrases.json";
import "./index.scss";

const ProfileBarMob = ({
	img,
	name,
	points,
}) => {
	const translate = useTranslate();

	return (
		<div className="profile-bar-mob">
			<div className="profile-bar-mob__avatar">
				<img src={img} alt="avatar"/>
			</div>
			<p className="profile-bar-mob__text">
				{name}
			</p>
			<p className="profile-bar-mob__points">
				{translate(phrases.profileBar2)}: {points}
				<img src="/img/point.svg" alt="point"/>
				{translate(phrases.profileBar3).toLowerCase()}
			</p>
		</div>
	);
};

export default ProfileBarMob;
