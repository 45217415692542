import { useEffect, useState } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { useTranslate } from "../../controllers/TranslateController";
import phrases from "../../phrases.json";
import "./index.scss";

const HeadSection = ({
	layout,
	logo,
	logoMob,
	navbar,
	navbarMob,
	lang,
	auth,
	authMob,
	profileMob
}) => {
	const translate = useTranslate();
	const [isOpenMenu, setIsOpenMenu] = useState(false);
	const [isHeaderFixed, setIsHeaderFixed] = useState(false);

	useEffect(() => {
		const updatePosition = () => {
			if (window.pageYOffset > 100) {
				setIsHeaderFixed(true);
			} else {
				setIsHeaderFixed(false);
			}
		}
		window.addEventListener("scroll", updatePosition);
		updatePosition();
		return () => window.removeEventListener("scroll", updatePosition);
	}, []);

	return (
		<div className="head-section">
			<div className="head-section__wrap">
				{layout(
					<>
						<div className="head-section__header">
							<div className={`head-section__header-wrap ${isHeaderFixed ? 'head-section__header-wrap_fixed' : ''}`}>
								<div className="head-section__header-left">
									<a target="blank" href="https://skin.club" className="head-section__logo">
										{logo}
									</a>
									<div className="head-section__nav">
										{navbar}
									</div>
								</div>
								<div className="head-section__header-right">
									<div className="head-section__lang">
										{lang}
									</div>
									<div className="head-section__auth">
										{auth}
									</div>
								</div>
							</div>
						</div>
						<div className="head-section__mob-header">
							<div className="head-section__mob-header-wrap">
								<div className="head-section__mob-header-left">
									<a target="blank" href="https://skin.club" className="head-section__mob-logo">
										{logoMob}
									</a>
								</div>
								<div className="head-section__mob-header-right">
									<button onClick={() => setIsOpenMenu((prev) => !prev)} className="head-section__mob-menu-btn">
										{isOpenMenu ?
											<img src="/img/menu-open.svg" alt="icon"/>
											:
											<img src="/img/menu-close.svg" alt="icon"/>
										}
									</button>
									<div className="head-section__mob-auth">
										{authMob}
									</div>
								</div>
								{isOpenMenu && (
									<div className="head-section__mob-menu">
										<div className="head-section__mob-nav">
											{navbarMob}
										</div>
										<div className="head-section__mob-lang">
											{lang}
										</div>
									</div>
								)}
								{!!profileMob && (
									<div className="head-section__mob-profile">
										{profileMob}
									</div>
								)}
							</div>
						</div>
						<div className="head-section__body">
							<AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce={true}>
								<div className="head-section__body-left">
									<p className="head-section__body-title">{translate(phrases.headSection1)} <a target="blank" href="https://skin.club">Skin.club!</a></p>
									<p className="head-section__body-text">{translate(phrases.headSection2)} <span>{translate(phrases.headSection3).toLowerCase()}</span></p>
								</div>
							</AnimationOnScroll>
							<div className="head-section__body-right">
								<img className="head-section__body-intel" src="/img/logo-intel.png" alt="logo intel"/>
								<p className="head-section__body-date">{translate(phrases.headSection4)}</p>
							</div>
						</div>
					</>
				)}
				<img className="head-section__weapon-one" src="/img/head-weapon-one.png" alt="head weapon one"/>
				<img className="head-section__weapon-two" src="/img/head-weapon-two.png" alt="head weapon two"/>
			</div>
		</div>
	);
};

export default HeadSection;
