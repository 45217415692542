import { useState, useEffect } from "react";
import ForecastSection from "../../sections/ForecastSection";
import GridTeam from "../../components/GridTeam";
import { usePlaceBetStageGrid } from "../../controllers/StagesController";
import EmptyWithReturn from "../../layouts/EmptyWithReturn";

export const turnIntoGridItems = (initItems) => {
	if (!initItems) {
		return {};
	}
	let four = initItems["1/4"];
	let two = initItems["1/2"];
	let one = initItems["1/1"];
	const createItem = (match) => ({
		logo: match?.team_id_passed?.logo,
		id: match?.team_id_passed?.id,
		name: match?.team_id_passed?.name,
		win: match?.bet?.right
	});
	return ({
		0: createItem(four[0]),
		1: createItem(four[1]),
		2: createItem(four[2]),
		3: createItem(four[3]),
		4: createItem(two[0]),
		5: createItem(two[1]),
		6: createItem(one[0])
	});
}

const turnIntoPlaceBetStage = (items, stageGrid) => {
	const createItem = (gridItem, item) => ({
		id: gridItem.id,
		teamId: item.id,
	});
	return {
		one: [
			createItem(stageGrid["1/1"][0], items[6])
		],
		two: [
			createItem(stageGrid["1/2"][0], items[4]),
			createItem(stageGrid["1/2"][1], items[5])
		],
		four: [
			createItem(stageGrid["1/4"][0], items[0]),
			createItem(stageGrid["1/4"][1], items[1]),
			createItem(stageGrid["1/4"][2], items[2]),
			createItem(stageGrid["1/4"][3], items[3])
		]
	};
}

const ForecastGridBlock = ({
	id,
	stageName,
	type,
	Drag,
	Drop,
	resultField,
	stageGrid,
	initItems,
	isWaitResult,
	reload = () => {},
	...props
}) => {
	const [isTimerEnd, setIsTimerEnd] = useState(false);
	const [items, setItems] = useState({});
	const [isReady, setIsReady] = useState(false);
	const [isWaitResultLocal, setIsWaitResultLocal] = useState(isWaitResult);
	const placeBetStageGrid = usePlaceBetStageGrid();

	useEffect(() => {
		setItems(turnIntoGridItems(initItems));
	}, [initItems]);

	useEffect(() => {
		if (Object.keys(items).length === 7) {
			setIsReady(true);
		} else {
			setIsReady(false);
		}
	}, [items]);

	const handleChange = (index, data) => {
		setItems((prev) => {
			let localItems = prev;
			localItems[index] = data;
			if (localItems[4]?.id !== localItems[0]?.id && localItems[4]?.id !== localItems[1]?.id) {
				delete localItems[4];
			}

			if (localItems[5]?.id !== localItems[2]?.id && localItems[5]?.id !== localItems[3]?.id) {
				delete localItems[5];
			}

			if (localItems[6]?.id !== localItems[5]?.id && localItems[6]?.id !== localItems[4]?.id) {
				delete localItems[6];
			}
			return {...localItems};
		});
	}

	const handleSubmit = () => {
		placeBetStageGrid(turnIntoPlaceBetStage(items, stageGrid))
		.then((response) => {
			setIsWaitResultLocal(true);
			reload();
		})
		.catch(console.log);
	}

	return (
		<ForecastSection
			{...props}
			stageName={stageName}
			isReady={isTimerEnd ? false : isReady}
			isWaitResult={isWaitResultLocal}
			onExpire={() => {
				setIsTimerEnd(true);
				reload()
			}}
			onSubmit={handleSubmit}>
			<GridTeam
				type={type}
				Drag={isTimerEnd ? EmptyWithReturn : Drag}
				Drop={isTimerEnd ? EmptyWithReturn : Drop}
				offSelect={isTimerEnd}
				resultField={resultField}
				teams={stageGrid["1/4"]}
				items={items}
				onChange={handleChange}/>
		</ForecastSection>
	);
}

export default ForecastGridBlock;