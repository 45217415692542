import "./index.scss";

const ButtonMiddle = ({ onClick, children, className }) => {
	return (
		<button className={`button-middle ${className || ''}`} onClick={onClick}>
			{children}
		</button>
	);
};

export default ButtonMiddle;
