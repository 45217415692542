import { useSelector } from 'react-redux';
import { useTranslate } from "../../controllers/TranslateController";
import phrases from "../../phrases.json";
import "./index.scss";

const EndStagesSection = ({
	layout
}) => {
	const config = useSelector((state) => state.config.config);
	const translate = useTranslate();
	return (
		<div className="end-stages-section">
			{layout(
				<div className="end-stages-section__box">
						<div className="end-stages-section__title">
							<p>{translate(phrases.endStagesSection1)}</p>
							<span>{translate(phrases.endStagesSection2)}</span>
						</div>
						<p className="end-stages-section__text">{translate(phrases.endStagesSection3)}</p>
						<div className="end-stages-section__wrap">
							<a className="end-stages-section__link" href={config.facebook}>
								<svg viewBox="0 0 30 30">
									<use href="/img/icon-facebook.svg#icon-facebook"/>
								</svg>
							</a>
							<a className="end-stages-section__link" href={config.youtube}>
								<svg viewBox="0 0 30 30">
									<use href="/img/icon-youtube.svg#icon-youtube"/>
								</svg>
							</a>
							<a className="end-stages-section__link" href={config.discord}>
								<svg viewBox="0 0 30 30">
									<use href="/img/icon-discord.svg#icon-discord"/>
								</svg>
							</a>
							<a className="end-stages-section__link" href={config.twitter}>
								<svg viewBox="0 0 30 30">
									<use href="/img/icon-twitter.svg#icon-twitter"/>
								</svg>
							</a>
					</div>
				</div>
			)}
		</div>
	);
}

export default EndStagesSection;