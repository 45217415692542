import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { AnimationOnScroll } from "react-animation-on-scroll";
import HeadSection from "../../sections/HeadSection";
import IntroductionSection from "../../sections/IntroductionSection";
import ForecastSection from "../../sections/ForecastSection";
import PassedStageSection from "../../sections/PassedStageSection";
import StageLockSection from "../../sections/StageLockSection";
import TotalScoreSection from "../../sections/TotalScoreSection";
import GiveawaySection from "../../sections/GiveawaySection";
import ResultsSection from "../../sections/ResultsSection";
import NotificationsSection from "../../sections/NotificationsSection";
import PromoSection from "../../sections/PromoSection";
import FooterSection from "../../sections/FooterSection";
import EndStagesSection from "../../sections/EndStagesSection";
import Container from "../../layouts/Container";
import Drag from "../../layouts/Drag";
import Drop from "../../layouts/Drop";
import Empty from "../../layouts/Empty";
import EmptyWithReturn from "../../layouts/EmptyWithReturn";
import Logo from "../../components/Logo";
import LogoMob from "../../components/LogoMob";
import Navbar from "../../components/Navbar";
import Socialbar from "../../components/Socialbar";
// import Policybar from "../../components/Policybar";
import LangSelect from "../../components/LangSelect";
import ProfileBar from "../../components/ProfileBar";
import ProfileBarMob from "../../components/ProfileBarMob";
import Button from "../../components/Button";
import ButtonMiddle from "../../components/ButtonMiddle";
import ButtonAuthMob from "../../components/ButtonAuthMob";
import WeaponCard from "../../components/WeaponCard";
import DragTeams from "../../components/DragTeams";
import TableTeams from "../../components/TableTeams";
import GridTeam from "../../components/GridTeam";
import ModalConfirm from "../../components/ModalConfirm";
import ModalEmailTarde from "../../components/ModalEmailTarde";
import ForecastTableBlock, { turnIntoTableItems } from "../../blocks/ForecastTableBlock";
import ForecastGridBlock, { turnIntoGridItems } from "../../blocks/ForecastGridBlock";
import { loginRedirect, useFetchUser } from "../../controllers/AuthController";
import { useTranslate } from "../../controllers/TranslateController";
import {
	useStagesPackaging,
	useExistResponseStage,
	calcPoints,
	calcGridPoints,
	useGetTeamsById,
	useGetStageById,
	useFetchStages
} from "../../controllers/StagesController";
import { logout } from '../../redux/auth';
import phrases from "../../phrases.json";
import "./index.scss";

const Main = () => {
	const accessToken = useSelector(state => state.auth.accessToken);
	const stages = useSelector(state => state.stage.stages);
	const user = useSelector(state => state.auth.user);
	const config = useSelector((state) => state.config.config);
	const dispatch = useDispatch();
	const {
		closeTableStages,
		closeGridStages,
		openTableStages,
		openGridStages,
		lockStages,
		isEndStages
	} = useStagesPackaging(stages);
	const getTeamsById = useGetTeamsById();
	const getStageById = useGetStageById();
	const existResponseStage = useExistResponseStage();
	const fetchStages = useFetchStages();
	const fetchUser = useFetchUser();
	const translate = useTranslate();

	const renderPassedStageSectionTable = () => closeTableStages.map((stage) => {
		const existResponse = existResponseStage(stage.id);
		return (
			<div key={stage.id} className="main-page__passed-stage-section">
				<PassedStageSection
					layout={(content) =>
						<Container>
							{content}
						</Container>
					}
					lock={!existResponse}
					title={`${translate(phrases.mainPage1)} ${stage.name}`}
					lockTitle={stage.name}
					resultPoints={calcPoints(existResponse)}>
					<TableTeams
						type="team"
						Drop={EmptyWithReturn}
						resultField="win"
						offSelect={true}
						items={turnIntoTableItems(existResponse)}/>
				</PassedStageSection>
			</div>
		)
	});

	const renderPassedStageSectionGrid = () => closeGridStages.map((stage) => {
		const existResponse = existResponseStage(stage.id);
		return (
			<div key={stage.id} className="main-page__passed-stage-section">
				<PassedStageSection
					layout={(content) =>
						<Container>
							{content}
						</Container>
					}
					lock={!existResponse}
					title={`${translate(phrases.mainPage1)} ${stage.name}`}
					lockTitle={stage.name}
					resultPoints={calcGridPoints(existResponse)}>
					<GridTeam
						type="grid-team"
						Drag={EmptyWithReturn}
						Drop={EmptyWithReturn}
						resultField="win"
						offSelect={true}
						teams={existResponse ? existResponse["1/4"] : {}}
						items={turnIntoGridItems(existResponse)}/>
				</PassedStageSection>
			</div>
		)}
	);

	const renderLockSection = () => lockStages.map((stage) => (
		<div key={stage.id} className="main-page__stage-lock-section">
			<StageLockSection
				layout={(content) =>
					<Container>
						{content}
					</Container>
				}
				titleMob={`${stage.name} ${translate(phrases.mainPage2).toLowerCase()}`}
				title={`${stage.name} ${translate(phrases.mainPage2).toLowerCase()}`}/>
		</div>
	));

	const renderTextLogin = () => {
		let arr = translate(phrases.mainPage4).toLowerCase().match(/(.+)(\s+\S+)$/);
		if (!arr) {
			return translate(phrases.mainPage4).toLowerCase();
		}
		return <>
			{arr[1]}<span>{arr[2]}</span>
		</>
	}

	const renderForecastSectionTable = () => openTableStages.map((stage) => {
		const existResponse = existResponseStage(stage.id);
		const teams = getTeamsById(stage.id);
		const existResult = !!stage.event_over;
		return (
			<div key={stage.id} className="main-page__forecast-section">
				<ForecastTableBlock
					id={stage.id}
					stageName={stage.name}
					type="team"
					Drag={existResponse ? EmptyWithReturn : Drag}
					Drop={existResponse ? EmptyWithReturn : Drop}
					resultField="win"
					teams={teams}
					layout={(content) =>
						<Container>
							{content}
						</Container>
					}
					bid={2}
					time={moment(stage.date_start_event).format()}
					isNeedTrade={!user?.trade_url}
					isLogin={!!accessToken}
					isWaitResult={!!existResponse}
					existResult={existResult}
					resultPoints={calcPoints(existResponse)}
					initItems={existResponse}
					ModalEmailTarde={ModalEmailTarde}
					ModalConfirm={ModalConfirm}
					reload={() => {
						fetchUser();
						fetchStages();
					}}
					ButtonLogin={
						<Button onClick={loginRedirect}>
							<p>{renderTextLogin()}</p>
						</Button>
					}
					Button={ButtonMiddle}/>
			</div>
		);
	});

	const renderForecastSectionGrid = () => openGridStages.map((stage) => {
		const existResponse = existResponseStage(stage.id);
		const stageGrid = getStageById(stage.id);
		const existResult = !!stage.event_over;
		return (
			<div key={stage.id} className="main-page__forecast-section">
				<ForecastGridBlock
					id={stage.id}
					stageName={stage.name}
					type="grid-team"
					Drag={existResponse ? EmptyWithReturn : Drag}
					Drop={existResponse ? EmptyWithReturn : Drop}
					resultField="win"
					stageGrid={existResponse || stageGrid}
					layout={(content) =>
						<Container>
							{content}
						</Container>
					}
					bid={2}
					time={moment(stage.date_start_event).format()}
					isNeedTrade={!user?.trade_url}
					isLogin={!!accessToken}
					isWaitResult={!!existResponse}
					existResult={existResult}
					resultPoints={calcGridPoints(existResponse)}
					initItems={existResponse}
					ModalEmailTarde={ModalEmailTarde}
					ModalConfirm={ModalConfirm}
					reload={() => {
						fetchUser();
						fetchStages();
					}}
					ButtonLogin={
						<Button onClick={loginRedirect}>
							<p>{renderTextLogin()}</p>
						</Button>
					}
					Button={ButtonMiddle}/>
			</div>
		)
	});

	return (
		<div className="main-page">
			<div className="main-page__head-section">
				<HeadSection
					layout={(content) =>
						<Container noPadding={true}>
							{content}
						</Container>
					}
					logo={
						<Logo/>
					}
					logoMob={
						<LogoMob/>
					}
					navbar={
						<Navbar/>
					}
					navbarMob={
						<Navbar isMob={true}/>
					}
					lang={
						<LangSelect/>
					}
					auth={
						user ?
							<ProfileBar
								img={user.avatar || "/img/avatar.png"}
								name={user.login}
								points={user?.points || 0}
								onExit={() => dispatch(logout())}/>
							:
							<Button onClick={loginRedirect}>
								<p>{renderTextLogin()}</p>
							</Button>
					}
					profileMob={
						user ?
							<ProfileBarMob
								img={user.avatar || "/img/avatar.png"}
								name={user.login}
								points={user?.points || 0}/>
							:
							null
					}
					authMob={
						user ?
							<ButtonAuthMob isLogin={true} onClick={() => dispatch(logout())}/>
							:
							<ButtonAuthMob isLogin={false} onClick={loginRedirect}/>
					}/>
			</div>
			<div id="about"/>
			{config.isOpenResulWindow === "true" ?
				<div className="main-page__end-stages-section">
					<EndStagesSection
						layout={(content) =>
							<Container>
								{content}
							</Container>
						}/>
				</div>
				:
				<div className="main-page__introduction-section">
					<IntroductionSection/>
				</div>
			}
			<p className="main-page__info">
				{translate(phrases.mainPage8)}
			</p>
			<div id="pickem" style={{ top: -100, position: 'relative'}}/>
			{renderPassedStageSectionTable()}
			{renderPassedStageSectionGrid()}
			{renderForecastSectionTable()}
			{renderForecastSectionGrid()}
			{renderLockSection()}
			<div className="main-page__total-score-section">
				<TotalScoreSection
					layout={(content) =>
						<Container>
							{content}
						</Container>
					}
					score={user?.points || 0}/>
			</div>
			<div id="prizes"/>
			<div className="main-page__giveaway-section">
				<GiveawaySection
					layout={(content) =>
						<Container>
							<AnimationOnScroll animateIn="animate__fadeInRight" animateOnce={true}>
								{content}
							</AnimationOnScroll>
						</Container>
					}
					rang="silver"
					title={translate(phrases.mainPage5)}
					minPoints={10}
					points={user?.points || 0}
					imgDesktop="/img/silver-desktop.png"
					imgMob="/img/silver-mob.png"/>
			</div>
			<div className="main-page__giveaway-section">
				<GiveawaySection
					layout={(content) =>
						<Container>
							<AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce={true}>
								{content}
							</AnimationOnScroll>
						</Container>
					}
					rang="gold"
					title={translate(phrases.mainPage6)}
					minPoints={20}
					points={user?.points || 0}
					imgDesktop="/img/gold-desktop.png"
					imgMob="/img/gold-mob.png"/>
			</div>
			<div className="main-page__giveaway-section">
				<GiveawaySection
					layout={(content) =>
						<Container>
							<AnimationOnScroll animateIn="animate__fadeInRight" animateOnce={true}>
								{content}
							</AnimationOnScroll>
						</Container>
					}
					rang="diamond"
					title={translate(phrases.mainPage7)}
					minPoints={30}
					points={user?.points || 0}
					imgDesktop="/img/diamond-desktop.png"
					imgMob="/img/diamond-mob.png"/>
			</div>
			<div className="main-page__results-section">
				<ResultsSection
					layout={(content) =>
						<Container>
							{content}
						</Container>
					}/>
			</div>
			<div className="main-page__notifications-section">
				<NotificationsSection
					layout={(content) =>
						<Container>
							<AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce={true}>
								{content}
							</AnimationOnScroll>
						</Container>
					}
					Button={ButtonMiddle}/>
			</div>
			<div className="main-page__promo-section">
				<PromoSection
					layout={(content) =>
						<Container>
							{content}
						</Container>
					}
					code={config.promoCode}
					Button={ButtonMiddle}/>
			</div>
			<div id="contacts"/>
			<div id="contacts" className="main-page__footer-section">
				<FooterSection
					layout={(content) =>
						<Container>
							{content}
						</Container>
					}
					navbar={
						<Navbar/>
					}
					socialbar={
						<Socialbar/>
					}
					policybar={
						null
						//<Policybar/>
					}/>
			</div>
		</div>
	);
};

export default Main;
