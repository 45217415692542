import moment from "moment";
import { createSlice } from '@reduxjs/toolkit';

export const counterSlice = createSlice({
	name: 'stage',
	initialState: {
		stages: [],
		stagesById: {},
	},
	reducers: {
		setStages: (state, action) => {
			state.stages = action.payload.sort((a, b) => moment(a.date_start_event).format() > moment(b.date_start_event).format()) || [];
		},
		setStagesById: (state, action) => {
			state.stagesById = {
				...state.stagesById,
				[action.payload.id]: action.payload.stage
			}
		},
		clearStagesById: (state) => {
			state.stagesById = {};
		},
	},
});


export const { setStages, setStagesById, clearStagesById } = counterSlice.actions;

export default counterSlice.reducer;
