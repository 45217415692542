import { useTranslate } from "../../controllers/TranslateController";
import phrases from "../../phrases.json";
import "./index.scss";

const IntroductionSection = () => {
	const translate = useTranslate();
	return (
		<div className="introduction-section">
			<div className="introduction-section__container">
				<div className="introduction-section__left">
					<img className="introduction-section__img" src="/img/introduction-left.png" alt="introduction left"/>
					<p className="introduction-section__text">
						&nbsp;1.&nbsp;&nbsp;{translate(phrases.introductionSection1)}
					</p>
				</div>
				<div className="introduction-section__center">
					<img className="introduction-section__img" src="/img/introduction-center.png" alt="introduction center"/>
					<p className="introduction-section__img-text">
						3-0
					</p>
					<p className="introduction-section__text">
						2.&nbsp;&nbsp;{translate(phrases.introductionSection2)}
						<img src="/img/point.svg" alt="point"/>
						{translate(phrases.introductionSection3).toLowerCase()}
					</p>
				</div>
				<div className="introduction-section__right">
					<img className="introduction-section__img" src="/img/introduction-right.png" alt="introduction right"/>
					<p className="introduction-section__text">
						3.&nbsp;&nbsp;{translate(phrases.introductionSection4)}
					</p>
				</div>
			</div>
		</div>
	);
};

export default IntroductionSection;
