import { useRef, useEffect, useState } from 'react';
import moment from "moment";
import { useTimer } from "react-timer-hook";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { useTranslate } from "../../controllers/TranslateController";
import phrases from "../../phrases.json";
import "./index.scss";

const ForecastSection = ({
	layout,
	bid = '',
	time = '',
	stageName = '',
	isLogin,
	ButtonLogin,
	isNeedTrade = false,
	onSubmit = () => {},
	isReady,
	isWaitResult,
	existResult,
	resultPoints = '',
	ModalEmailTarde,
	ModalConfirm,
	onExpire = () => {},
	children
}) => {
	const [isTimerEnd, setIsTimerEnd] = useState(false);
	const {
		minutes,
		hours,
		days,
		seconds,
	} = useTimer({
		expiryTimestamp: moment(time).valueOf(),
		autoStart: true,
		onExpire: () => {
			setIsTimerEnd(true);
			onExpire();
		}
	});
	const dialogEmailTardeRef = useRef();
	const dialogConfirmRef = useRef();
	const translate = useTranslate();

	const handleSubmit = () => {
		onSubmit();
	}

	const handleCheck = () => {
		if (isNeedTrade) {
			dialogEmailTardeRef.current.open();
			return;
		}
		dialogConfirmRef.current.open();
	}

	const renderButton = () => {
		if (!isLogin) {
			return (
				<div className="forecast-section__wrap-btn">
					{ButtonLogin}
				</div>
			);
		}
		if (existResult) {
			return (
				<div className="forecast-section__wrap-btn forecast-section__wrap-btn_center forecast-section__wrap-btn_big">
					<div className="forecast-section__btn">
						<p>{resultPoints} {translate(phrases.forecastSection1).toLowerCase()} {String(stageName).toLowerCase()}</p>
						<img src="/img/point.svg" alt="point"/>
					</div>
				</div>
			);
		}
		if (isWaitResult) {
			return (
				<div className="forecast-section__wrap-btn">
					<div className="forecast-section__btn forecast-section__btn_disable">
						<p>{translate(phrases.forecastSection2)}</p>
					</div>
				</div>
			);
		}
		if (!isReady) {
			return (
				<div className="forecast-section__wrap-btn">
					<div className="forecast-section__btn forecast-section__btn_disable">
						<p>{translate(phrases.forecastSection3)}</p>
					</div>
				</div>
			);
		}
		return (
			<div className="forecast-section__wrap-btn">
				<button onClick={handleCheck} className="forecast-section__btn">
					<p>{translate(phrases.forecastSection3)}</p>
				</button>
			</div>
		);
	};

	return (
		<>
			<div className="forecast-section">
				{layout(
					<>
						<AnimationOnScroll animateIn="animate__fadeInRight" animateOnce={true}>
							<p className="forecast-section__title">
								{translate(phrases.forecastSection4)} {bid} 
								<img src="/img/point.svg" alt="point"/>
								{translate(phrases.forecastSection5).toLowerCase()}
							</p>
						</AnimationOnScroll>
						<p className="forecast-section__text">
							{stageName} {translate(phrases.forecastSection6).toLowerCase()} | {!isTimerEnd ? (
								<>
									{translate(phrases.forecastSection7)}: <span>{days}d {hours}h {minutes}min {seconds}s</span>
								</>
								)
								:
								<span>{translate(phrases.forecastSection14)}</span>
							}
						</p>
					</>
				)}
				<div className="forecast-section__box">
					{layout(
						<div className="forecast-section__inner">
							{children}
							{renderButton()}
						</div>
					)}
				</div>
			</div>
			<ModalEmailTarde
				dialogRef={dialogEmailTardeRef}
				onSubmit={() => dialogConfirmRef.current.open()}/>
			<ModalConfirm
				dialogRef={dialogConfirmRef}
				title={translate(phrases.forecastSection10)}
				text={translate(phrases.forecastSection11)}
				btnTextYes={translate(phrases.forecastSection12)}
				btnTextNo={translate(phrases.forecastSection13)}
				onSubmit={handleSubmit}/>
		</>
	);
};

export default ForecastSection;
