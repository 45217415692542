import { useRef, useEffect, useState } from "react";
import { usePut } from '../../api/request';
import { loginRedirect, useFetchUser } from "../../controllers/AuthController";
import { useTranslate } from "../../controllers/TranslateController";
import phrases from "../../phrases.json";
import "./index.scss";

const ModalEmailTarde = ({ dialogRef, onSubmit }) => {
	const [values, setValues] = useState({
		email: '',
		tradeUrl: ''
	});
	const [errors, setErrors] = useState({});
	const dialogInnerRef = useRef();
	const fetchUser = useFetchUser();
	const put = usePut();
	const translate = useTranslate();

	useEffect(() => {
		dialogRef.current = {
			open: () => dialogInnerRef.current.showModal(),
			close: () => dialogInnerRef.current.close()
		};
	},[])

	const handleChange = (evt) => {
		setValues((prev) => ({
			...prev,
			[evt.target.name]: evt.target.value
		}));
		setErrors((prev) => ({
			...prev,
			[evt.target.name]: ''
		}));
	}

	const handleSubmit = () => {
		if (!values.tradeUrl) {
			setErrors((prev) => ({
				...prev,
				tradeUrl: translate(phrases.modalEmailTarde1).toLowerCase()
			}));
			return;
		}
		if (!values.email) {
			setErrors((prev) => ({
				...prev,
				email: translate(phrases.modalEmailTarde1).toLowerCase()
			}));
			return;
		}
		if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)) {
			setErrors((prev) => ({
				...prev,
				email: translate(phrases.modalEmailTarde1).toLowerCase()
			}));
			return;
		}
		const requestData = {
			trade_url: values.tradeUrl,
			email: values.email
		};
		put('users', requestData)
		.then((response) => {
			fetchUser();
			dialogInnerRef.current.close();
			onSubmit(values)
		})
		.catch((e) => {
			console.log(e);
		})
	}

	return (
		<dialog ref={dialogInnerRef} className="modal-email-tarde">
			<button onClick={() => dialogInnerRef.current.close()} className="modal-email-tarde__close">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none">
					<path d="M7.625 7.62503C8.09382 7.15635 8.72959 6.89307 9.3925 6.89307C10.0554 6.89307 10.6912 7.15635 11.16 7.62503L20 16.465L28.84 7.62503C29.3115 7.16964 29.943 6.91765 30.5985 6.92335C31.254 6.92904 31.881 7.19197 32.3445 7.65549C32.8081 8.11901 33.071 8.74604 33.0767 9.40153C33.0824 10.057 32.8304 10.6885 32.375 11.16L23.535 20L32.375 28.84C32.8304 29.3115 33.0824 29.943 33.0767 30.5985C33.071 31.254 32.8081 31.8811 32.3445 32.3446C31.881 32.8081 31.254 33.071 30.5985 33.0767C29.943 33.0824 29.3115 32.8304 28.84 32.375L20 23.535L11.16 32.375C10.6885 32.8304 10.057 33.0824 9.4015 33.0767C8.74601 33.071 8.11898 32.8081 7.65546 32.3446C7.19194 31.8811 6.92901 31.254 6.92332 30.5985C6.91762 29.943 7.16961 29.3115 7.625 28.84L16.465 20L7.625 11.16C7.15632 10.6912 6.89304 10.0554 6.89304 9.39253C6.89304 8.72962 7.15632 8.09385 7.625 7.62503Z" fill="#FFFEFE"/>
				</svg>
			</button>
			<p className="modal-email-tarde__title">
				{translate(phrases.modalEmailTarde5)}
			</p>
			<p className="modal-email-tarde__text">
				{translate(phrases.modalEmailTarde6)}&nbsp;&nbsp;
				<a target="blank" href="http://steamcommunity.com/profiles/76561198105442547/tradeoffers/privacy#trade_offer_access_url">
					{translate(phrases.modalEmailTarde7)}
				</a>
			</p>
			<div className="modal-email-tarde__wrap-input">
				<input
					className="modal-email-tarde__input"
					type="text"
					name="tradeUrl"
					value={values["tradeUrl"]}
					error={errors["tradeUrl"]}
					placeholder={translate(phrases.modalEmailTarde4)}
					onChange={handleChange}/>
				{errors["tradeUrl"] && (
					<p>{errors["tradeUrl"]}</p>
				)}
			</div>
			<p className="modal-email-tarde__text">
				{translate(phrases.modalEmailTarde8)}
			</p>
			<div className="modal-email-tarde__wrap-input">
				<input
					className="modal-email-tarde__input"
					type="text"
					name="email"
					value={values["email"]}
					error={errors["email"]}
					placeholder={translate(phrases.modalEmailTarde3)}
					onChange={handleChange}/>
				{errors["email"] && (
					<p>{errors["email"]}</p>
				)}
			</div>
			<button className="modal-email-tarde__btn" onClick={handleSubmit}>
				{translate(phrases.modalEmailTarde9)}
			</button>
		</dialog>
	);
};

export default ModalEmailTarde;
