import {
	createStore,
	combineReducers,
	applyMiddleware,
} from 'redux';
import {
	persistStore,
	persistReducer,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import auth from './redux/auth';
import stage from './redux/stage';
import config from './redux/config';
import translate from './redux/translate';

const appReducer = combineReducers({
	auth,
	stage,
	config,
	translate
});

const persistConfig = {
	key: 'root-pickem',
	storage,
	whitelist: ['auth', 'translate'],
};

const persistedReducer = persistReducer(persistConfig, appReducer);

const store = createStore(persistedReducer);

export default store;
export const persistor = persistStore(store);
