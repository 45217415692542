import { useSelector } from 'react-redux';
import "./index.scss";

const Socialbar = () => {
	const config = useSelector((state) => state.config.config);
	return (
		<ul className="socialbar">
			<li className="socialbar__item">
				<a className="socialbar__link" href={config.facebook}>
					<svg viewBox="0 0 30 30">
						<use href="/img/icon-facebook.svg#icon-facebook"/>
					</svg>
				</a>
			</li>
			<li className="socialbar__item">
				<a className="socialbar__link" href={config.youtube}>
					<svg viewBox="0 0 30 30">
						<use href="/img/icon-youtube.svg#icon-youtube"/>
					</svg>
				</a>
			</li>
			<li className="socialbar__item">
				<a className="socialbar__link" href={config.discord}>
					<svg viewBox="0 0 30 30">
						<use href="/img/icon-discord.svg#icon-discord"/>
					</svg>
				</a>
			</li>
		</ul>
	);
};

export default Socialbar;
