import { useSelector } from 'react-redux';
import { useTranslate } from "../../controllers/TranslateController";
import phrases from "../../phrases.json";
import "./index.scss";

const ResultsSection = ({
	layout
}) => {
	const config = useSelector((state) => state.config.config);
	const translate = useTranslate();
	return (
		<div className="results-section">
			{layout(
				<div className="results-section__box">
					<p className="results-section__text">
						{translate(phrases.resultsSection1)}
					</p>
					<a href={config.twitter} className="results-section__btn">
						<img src="/img/bird.svg" alt="bird"/>
						<p>
							{translate(phrases.resultsSection2)}
						</p>
					</a>
				</div>
			)}
		</div>
	);
};

export default ResultsSection;
