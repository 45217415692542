import { useState, useRef } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslate } from "../../controllers/TranslateController";
import { useAlert } from "../../components/ModalInfo";
import phrases from "../../phrases.json";
import "./index.scss";

const PromoSection = ({
	code,
	layout,
	Button,
	Modal
}) => {
	const [isOpenCopy, setIsOpenCopy] = useState(false);
	const alert = useAlert();
	const translate = useTranslate();

	const handleClick = () => {
		alert({
			title: translate(phrases.promoSection5),
			text: translate(phrases.promoSection6)
		})
	};

	return (
		<>
			<div className="promo-section">
				{layout(
					<div className="promo-section__box">
						<p className="promo-section__title">
							{translate(phrases.promoSection1)}
						</p>
						<p className="promo-section__text">
							{translate(phrases.promoSection2)}
						</p>
						<div className="promo-section__wrap">
							<div className="promo-section__code">
								{code}
							</div>
							<div className="promo-section__btn">
								<CopyToClipboard text={String(code).toUpperCase()} onCopy={handleClick}>
									<Button>
										<p>{translate(phrases.promoSection3).toUpperCase()}</p>
									</Button>
								</CopyToClipboard>
							</div>
							<p className="promo-section__text-promo">
								{translate(phrases.promoSection4)}
							</p>
						</div>
						<img className="promo-section__img-left" src="/img/promo-left.png" alt="promo left"/>
						<img className="promo-section__img-right" src="/img/promo-right.png" alt="promo right"/>
					</div>
				)}
			</div>
		</>
	);
};

export default PromoSection;
