import { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';
import { configureTransport } from '../../api/transport';
import { get, useGet } from '../../api/request';
import { setTokens, setUser } from '../../redux/auth';

export const loginRedirect = () => {
	get('auth/login')
	.then((response) => {
		window.location.href = response.data.url
	})
	.catch(console.log);
}

export const useFetchUser = () => {
	const accessToken = useSelector(state => state.auth.accessToken);
	const dispatch = useDispatch();
	const getHook = useGet();
	return (callback = () => {}) => {
		if (!accessToken) {
			dispatch(setUser());
			return;
		}
		getHook('users')
		.then((response) => {
			dispatch(setUser(response.data.user));
			callback();
		})
		.catch((e) => {
			console.log(e);
		})
	};
}


const AuthController = ({ children }) => {
	const navigate = useNavigate();
	const accessToken = useSelector(state => state.auth.accessToken);
	const fetchUser = useFetchUser();
	const dispatch = useDispatch();

	useEffect(() => {
		const query = queryString.parse(window.location.search);
		const authKey = query["auth_key"];
		let search = '';
		if (!authKey) {
			return;
		}
		get(`auth/key/${authKey}`)
		.then((response) => {
			dispatch(setTokens({
				accessToken: response.data.tokens.access_token,
				refreshToken: response.data.tokens.refresh_token
			}));
		})
		.catch((e) => {
			console.log(e);
		})
	}, [])

	useEffect(() => {
		configureTransport(accessToken);
		fetchUser(() => {
			navigate('/');
			
		})
	}, [accessToken]);

	return children;
}

export default AuthController;
