import { useEffect } from "react";
import { DndProvider } from "react-dnd";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { HTML5Backend } from "react-dnd-html5-backend";
import { useRoutes } from "react-router-dom";
import store, { persistor } from './store';
import navigation from "./navigation";
import AuthController from './controllers/AuthController';
import StagesController from './controllers/StagesController';
import ConfigController from './controllers/ConfigController';
import TranslateController from './controllers/TranslateController';
import SelectTeam from './components/SelectTeam';
import { AlertProvider } from './components/ModalInfo';
// import "./firebase.js";

const App = () => {
	const content = useRoutes(navigation);

	useEffect(() => {
		navigator.serviceWorker
		.register('/sp-push-worker-fb.js')
		.then(() => {})
		.catch((err) => {})
	}, []);

	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<DndProvider backend={HTML5Backend}>
					<TranslateController>
						<ConfigController>
							<AuthController>
								<StagesController>
									<SelectTeam>
										<AlertProvider>
											{content}
										</AlertProvider>
									</SelectTeam>
								</StagesController>
							</AuthController>
						</ConfigController>
					</TranslateController>
				</DndProvider>
			</PersistGate>
		</Provider>
	);
}

export default App;
