import { createSlice } from '@reduxjs/toolkit';

export const counterSlice = createSlice({
	name: 'config',
	initialState: {
		config: {}
	},
	reducers: {
		setConfig: (state, action) => {
			state.config = action.payload;
		}
	}
});

export const { setConfig } = counterSlice.actions;

export default counterSlice.reducer;
