import { useSelector, useDispatch } from 'react-redux';
import { setTokens, logout } from '../redux/auth';
import { getTransport, configureTransport } from "./transport";

const generateHook = (callback) => () => {
	const dispatch = useDispatch();
	const refreshToken = useSelector((state) => state.auth.refreshToken);

	return callback(async (e) => {
		if (e.response.status !== 401) throw e;
		try {
			const res = await get('auth/refresh', { headers: { Authorization: `Bearer ${refreshToken}`}});
			configureTransport(res.data.token.access_token);
			dispatch(setTokens({
				accessToken: res.data.token.access_token,
				refreshToken: res.data.token.refresh_token,
			}));
			const buf = (await getTransport()({
				...e.response.config,
				headers: { 'Authorization': 'Bearer ' + res.data.token.access_token }
			}))?.data;
			return buf;
		}
		catch (err) {
			dispatch(logout());
			throw e;
		}
	});
};

export const usePost = generateHook((middleware) => (
	(path, payload, config) => getTransport()
	.post(`/${path}`, payload, config)
	.then((response) => response.data)
	.catch(middleware)
	));

export const useGet = generateHook((middleware) => (
	(path, token) => getTransport(token)
	.get(`/${path}`)
	.then((response) => response.data)
	.catch(middleware)
	));

export const usePut = generateHook((middleware) => (
	(path, payload, config) => getTransport()
	.put(`/${path}`, payload, config)
	.then((response) => response.data)
	.catch(middleware)
	));

export const usePatch = generateHook((middleware) => (
	(path, payload, config) => getTransport()
	.patch(`/${path}`, payload, config)
	.then((response) => response.data)
	.catch(middleware)
	));

export const useDelete = generateHook((middleware) => (
	(path, config) => getTransport()
	.delete(`/${path}`, config)
	.then((response) => response.data)
	.catch(middleware)
	));

export const get = (path, config) => getTransport()
	.get(`/${path}`, config).then((response) => response.data);

export const post = async (path, payload, config) => getTransport()
	.post(`/${path}`, payload, config)
	.then((response) => response.data);

export const put = (path, payload = {}) => getTransport()
	.put(`/${path}`, payload)
	.then((response) => response.data);

export const patch = (path, payload = {}) => getTransport()
	.patch(`/${path}`, payload)
	.then((response) => response.data);

export const httpDelete = (path, config) => getTransport()
	.delete(`/${path}`, config)
	.then((response) => response.data);