import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "animate.css/animate.min.css";
import App from "./App";
import getEnvVars from "./api/environment";

const { pushScript } = getEnvVars();

const root = ReactDOM.createRoot(document.getElementById("root"));

const head = document.getElementsByTagName("head")[0];
const scriptPushElement = document.createElement("script");
scriptPushElement.charset = "UTF-8";
scriptPushElement.async = true;
scriptPushElement.src = pushScript;
head?.append(scriptPushElement);

root.render(
	<BrowserRouter>
		<App />
	</BrowserRouter>
);

