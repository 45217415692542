import { useState } from "react";
import { useTranslate } from "../../controllers/TranslateController";
import { useSelectTeam } from "../../components/SelectTeam";
import phrases from "../../phrases.json";
import getEnvVars from "../../api/environment";
import "./index.scss";

const { uploadsUrl } = getEnvVars();

const TableTeams = ({
	type,
	items,
	teams = [],
	offSelect,
	onChange = () => {},
	resultField,
	Drop
}) => {
	const {
		openSelectTeam
	} = useSelectTeam();
	const translate = useTranslate();

	const handleSelect = (index) => {
		if (offSelect) {
			return;
		}
		openSelectTeam(teams.map((team) => {
			return {
				img: team.team_logo,
				id: team.id,
				data: team
			};
		}), (data) => {
			onChange(index, data)
		});
	};

	const renderResult = (data) => {
		if (!data) {
			return null;
		}
		if (resultField === undefined) {
			return null;
		}
		if (data[resultField] === true) {
			return <img className="table-teams__success" src="/img/result-team-success.svg" alt="success"/>;
		}
		if (data[resultField] === false) {
			return <img className="table-teams__error" src="/img/result-team-error.svg" alt="error"/>;
		}
		return null;
	}

	const renderTextWin = () => {
		let arr = translate(phrases.tableTeams1).match(/(^\S+\s)(.+)(\s+\S+\s+\S+)$/);
		if (!arr) {
			return translate(phrases.tableTeams1);
		}
		return <>
			<span>{arr[1]}</span>{arr[2]}<span>{arr[3]}</span>
		</>
	}

	const renderTextLose = () => {
		let arr = translate(phrases.tableTeams2).match(/(^\S+\s)(.+)(\s+\S+\s+\S+\s+\S+)$/);
		if (!arr) {
			return translate(phrases.tableTeams2);
		}
		return <>
			<span>{arr[1]}</span>{arr[2]}<span>{arr[3]}</span>
		</>
	}

	const renderTextPick = () => {
		let arr = translate(phrases.tableTeams4).match(/(^\S+\s)(.+)(\s+\S+\s+\S+\s+\S+\s+\S+)$/);
		if (!arr) {
			return translate(phrases.tableTeams4);
		}
		return <>
			<span>{arr[1]}</span>{arr[2]}<span>{arr[3]}</span>
		</>
	}

	const renderCell = (index) => {
		return (
			<div className={`table-teams__wrap table-teams__wrap_${index}`}>
				{index == 0 && (
					<>
						<p className="table-teams__title">
							{renderTextWin()}
						</p>
						<span>3-0</span>
					</>
				)}
				{index == 1 && (
					<>
						<p className="table-teams__title">
							{renderTextLose()}
						</p>
						<span>0-3</span>
					</>
				)}
				<Drop
					type={type}
					onDrop={(data) => onChange(index, data)}>
					{({ isOver, ref }) => 
						<div ref={ref} className={`table-teams__cell ${isOver ? 'table-teams__cell_over' : ''}`}>
							{renderResult(items[index])}
							{items[index] ?
								<img src={uploadsUrl + items[index].team_logo} alt="team"/>
								:
								<p>
									<span>?</span>
									{translate(phrases.tableTeams3)}
								</p>
							}
						</div>
						}
				</Drop>
			</div>
		);
	};

	const renderCellMob = (index) => {
		return (
			<div className={`table-teams__wrap table-teams__wrap_${index}`}>
				{index == 0 && (
					<>
						<p className="table-teams__title">
							{renderTextWin()}
						</p>
						<span>3-0</span>
					</>
				)}
				{index == 1 && (
					<>
						<p className="table-teams__title">
							{renderTextLose()}
						</p>
						<span>0-3</span>
					</>
				)}
				<div onClick={() => handleSelect(index)} className="table-teams__cell">
					{renderResult(items[index])}
					{items[index] ?
						<img src={uploadsUrl + items[index].team_logo} alt="team"/>
						:
						<p>
							<span>?</span>
							{translate(phrases.tableTeams3)}
						</p>
					}
				</div>
			</div>
		);
	};

	return (
		<div className="table-teams">
			<div className="table-teams__mob">
				{renderCellMob(0)}
				{renderCellMob(1)}
				<div className="table-teams__wrap table-teams__wrap_9">
					<p className="table-teams__mob-pick">{renderTextPick()}</p>
				</div>
				{renderCellMob(2)}
				{renderCellMob(3)}
				{renderCellMob(4)}
				{renderCellMob(5)}
				{renderCellMob(6)}
				{renderCellMob(7)}
				{renderCellMob(8)}
			</div>
			<div className="table-teams__desktop">
				{renderCell(0)}
				{renderCell(1)}
				<div className="table-teams__wrap table-teams__wrap_9">
					<p className="table-teams__pick">{renderTextPick()}</p>
				</div>
				{renderCell(2)}
				{renderCell(3)}
				{renderCell(4)}
				{renderCell(5)}
				{renderCell(6)}
				{renderCell(7)}
				{renderCell(8)}
			</div>
		</div>
	);
};

export default TableTeams;
