import "./index.scss";

const StageLockSection = ({
	layout,
	title,
	titleMob,
	subTitleMob
}) => {
	return (
		<div className="stage-lock-section">
			{layout(
				<>
					<p className="stage-lock-section__title">
						{title}
					</p>
					<p className="stage-lock-section__mob-title">
						{titleMob}
					</p>
					<div className="stage-lock-section__box">
						<img className="stage-lock-section__img" src="/img/stage-lock-section-img.png" alt="image"/>
						<svg className="stage-lock-section__lock" viewBox="0 0 50 50">
							<use href="/img/lock.svg#lock"/>
						</svg>
					</div>
				</>
			)}
		</div>
	);
};

export default StageLockSection;
