import { createSlice } from '@reduxjs/toolkit';
import { configureTransport } from '../../api/transport';

export const counterSlice = createSlice({
	name: 'auth',
	initialState: {
		accessToken: '',
		refreshToken: '',
		// firebaseToken: '',
		user: null
	},
	reducers: {
		setTokens: (state, action) => {
			configureTransport(action.payload.accessToken);
			state.accessToken = action.payload.accessToken;
			state.refreshToken = action.payload.refreshToken;
		},
		// setFirebaseToken: (state, action) => {
		// 	state.firebaseToken = action.payload;
		// },
		setUser: (state, action) => {
			state.user = action.payload || null;
		},
		logout: (state, action) => {
			configureTransport('');
			state.accessToken = '';
			state.refreshToken = '';
			state.user = null;
		}
	},
});

export const { setTokens, logout, setUser/*, setFirebaseToken*/ } = counterSlice.actions;

export default counterSlice.reducer;
